<template>
  <div>
    <slot name="header" />

    <div class="container mx-auto pt-8 pb-12 max-w-3xl text-center">
      <div class="my-8">
        <a
          :href="returnUrl"
          class="px-12 py-3 underline rounded text-gray-800">Tilbake til {{ $app.name }}</a>
      </div>
    </div>
    <slot :is-valid="false" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    returnUrl () {
      return this.data.returnUrl || this.$app.return_url || "#"
    }
  }
}
</script>
